function catalogItemCounter(field){

	var fieldCount = function(el) {

		var 
					// Мин. значение
					min = el.data('min') || false,

					// Макс. значение
					max = el.data('max') || false, 

					// Кнопка уменьшения кол-ва
					dec = el.prev('.dec'), 

					// Кнопка увеличения кол-ва
					inc = el.next('.inc');

					function init(el) {
						if(!el.attr('disabled')){
							dec.on('click', decrement);
							inc.on('click', increment);
						}

					// Уменьшим значение
					function decrement() {
						var value = parseInt(el[0].value);
						value--;

						if(!min || value >= min) {
							el[0].value = value;
						}
					};

					// Увеличим значение
					function increment() {
						var value = parseInt(el[0].value);

						value++;

						if(!max || value <= max) {
							el[0].value = value++;
						}
					};
					
				}

				el.each(function() {
					init($(this));
				});
			};

			$(field).each(function(){
				fieldCount($(this));
			});
		}

		catalogItemCounter('.fieldCount');

		jQuery(function($) {


			$(".sf-menu").superfish({
				delay: 200,
				speed: "fast",
				cssArrows: false
			})
			.after("<div id='mobile-menu'>").clone().appendTo("#mobile-menu");
			$("#mobile-menu").children("ul").removeClass("sf-menu");
			$("#mobile-menu").mmenu({
				extensions: [ 'widescreen', 'theme-white', 'effect-menu-slide', 'pagedim-black' ],
				navbar: {
					title: "Меню"
				}
			});

			$("a.go-cart").clone().appendTo(".cart-mobile-wrap").removeClass("go-cart").addClass("go-cart-mobile");




			$(".filter-block .title").on( "click", function() {
				$(this).parent().find(".options").toggle();
			});

			$(".sidebar h4").on( "click", function() {
				$(".mobile-wrap").toggle();
			});

jQuery("#slider").slider({
	min: 0,
	max: 1000,
	values: [0,1000],
	range: true,
	stop: function(event, ui) {
		jQuery("input#minCost").val(jQuery("#slider").slider("values",0));
		jQuery("input#maxCost").val(jQuery("#slider").slider("values",1));
    },
    slide: function(event, ui){
		jQuery("input#minCost").val(jQuery("#slider").slider("values",0));
		jQuery("input#maxCost").val(jQuery("#slider").slider("values",1));
    }
});
jQuery("input#minCost").change(function(){
	var value1=jQuery("input#minCost").val();
	var value2=jQuery("input#maxCost").val();

    if(parseInt(value1) > parseInt(value2)){
		value1 = value2;
		jQuery("input#minCost").val(value1);
	}
	jQuery("#slider").slider("values",0,value1);	
});

	
jQuery("input#maxCost").change(function(){
	var value1=jQuery("input#minCost").val();
	var value2=jQuery("input#maxCost").val();
	
	if (value2 > 1000) { value2 = 1000; jQuery("input#maxCost").val(1000)}

	if(parseInt(value1) > parseInt(value2)){
		value2 = value1;
		jQuery("input#maxCost").val(value2);
	}
	jQuery("#slider").slider("values",1,value2);
});



			$("button.gird").on('click', function() {
				$(".btn-group button.gird").removeClass("active");
				$(this).addClass("active");
			});

			$(".product-slider").owlCarousel({
			autoPlay: 7000, //Set AutoPlay to 3 seconds
			items : 4,
			itemsDesktop : [1199,4],
			itemsDesktopSmall : [979,2],
			navigation: true,
			navigationText: [],
			stopOnHover:true
		});

			$(".logo-slider").owlCarousel({
			autoPlay: 7000, //Set AutoPlay to 3 seconds
			items : 5,
			itemsDesktop : [1199,5],
			itemsDesktopSmall : [979,2],
			navigation: true,
			navigationText: [],
			stopOnHover:true
		});

			$("#feedback-slider").owlCarousel({
			autoPlay: 7000, //Set AutoPlay to 3 seconds
			items : 1,
			itemsDesktop : [1199,1],
			itemsDesktopSmall : [979,1],
			navigation: false,
			pagination: true,
			transitionStyle : "fade",
			stopOnHover:true 
		});

			$(".fancybox").fancybox();

			/*$("ul.thumbnails li a").fancybox({
				"padding" : 20,
				"imageScale" : false, 
				"zoomOpacity" : false,
				"zoomSpeedIn" : 1000,	
				"zoomSpeedOut" : 1000,	
				"zoomSpeedChange" : 1000, 
				"frameWidth" : 700,	 
				"frameHeight" : 600, 
				"overlayShow" : true, 
				"overlayOpacity" : 0.8,	
				"hideOnContentClick" :false,
				"centerOnScroll" : false
			});*/
			$(".magnific .item").magnificPopup({
				delegate: 'a',
				type: 'image',
				tLoading: 'Loading image #%curr%...',
				mainClass: 'mfp-img-mobile',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			titleSrc: function(item) {
				return item.el.attr('title');
			}
		}
	});

			$(".tab_item").not(":first").hide();
			$(".wrapper .tab").click(function() {
				$(".wrapper .tab").removeClass("active").eq($(this).index()).addClass("active");
				$(".tab_item").hide().eq($(this).index()).fadeIn()
			}).eq(0).addClass("active");

	//E-mail Ajax Send
	//Documentation & Example: https://github.com/agragregra/uniMail
	$("form").submit(function() { //Change
		var th = $(this);
		$.ajax({
			type: "POST",
			url: "mail.php", //Change
			data: th.serialize()
		}).done(function() {
			alert("Thank you!");
			setTimeout(function() {
				// Done Functions
				th.trigger("reset");
			}, 1000);
		});
		return false;
	});

	//Chrome Smooth Scroll
	try {
		$.browserSelector();
		if($("html").hasClass("chrome")) {
			$.smoothScroll();
		}
	} catch(err) {

	};

	$("img, a").on("dragstart", function(event) { event.preventDefault(); });

	$("form.form-style .phone").mask('+38 (999) 99-99-999');

});
